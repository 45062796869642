import { combineReducers } from 'redux';
import { projects, projectsHasErrored, projectsIsLoading } from "./projects";
import { events, eventsHasErrored, eventsIsLoading } from "./events";
import { eventsAll } from "./eventsAll";
import { event, eventHasErrored, eventIsLoading } from "./event";
import { pollData, pollHasErrored, pollIsLoading, pollResults} from "./poll";
import { materials, materialsHasErrored, materialsIsLoading, userData, materialsSubscribeError, materialsSubscribeProcessing} from './materials';
import { material, materialHasErrored, materialIsLoading} from './material';
import { membersData, membersProjectData, membersIsLoading, membersHasErrored, membersCardDialog } from './members'
import {elkPage} from "./elkPage";
import {exchange} from "./exchange";
import {fireBase} from "./firebase";
import {rcClub} from "./rcClub";
import Messaging from "./Messaging";
import Chat from "./Chat";

export default combineReducers({
    projects,
    projectsHasErrored,
    projectsIsLoading,
    events,
    eventsAll,
    eventsHasErrored,
    eventsIsLoading,
    event,
    eventHasErrored,
    eventIsLoading,
    pollData,
    pollHasErrored,
    pollIsLoading,
    pollResults,
    materials,
    materialsIsLoading,
    materialsHasErrored,
    materialsSubscribeError,
    materialsSubscribeProcessing,
    userData,
    material,
    materialHasErrored,
    materialIsLoading,
    membersData, membersProjectData, membersIsLoading, membersHasErrored, membersCardDialog,
    elkPage,
    exchange,
    fireBase,
    rcClub,
    Messaging,
    Chat
});
