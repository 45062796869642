import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { language } from "@ksbteam/core/api/DummyTranslate";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        "& p": {
            marginBottom: theme.spacing(),
        },
        "& ul li": {
            marginInlineStart: "2.5em",
        },
        "& ol li": {
            marginInlineStart: "1em",
        },
        "& .section-title": {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    list: {
        listStyleType: "none",
        paddingLeft: "0",
        "& li::before": {
            content: "'-'",
            display: "inline-block",
            width: "1em",
            marginLeft: "-1em",
        },
    },
}));

export default function ConsentDataTransfer({ isCitizenshipNeeded }) {
    return language === "en" ? <En isCitizenshipNeeded={isCitizenshipNeeded}/> : <Ru isCitizenshipNeeded={isCitizenshipNeeded}/>;
}

function Ru({ isCitizenshipNeeded }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Box display={"flex"} justifyContent={"center"}>
                <Typography variant={"h2"} gutterBottom>
                    PROVISION OF CONSENT TO PROCESSING PERSONAL DATA
                </Typography>
            </Box>
            <Typography gutterBottom>
                I hereby provide consent to the Roscongress Foundation
                (hereinafter referred to as the Foundation, OGRN 1077799005426,
                INN 7706412930), registered at the following address (legal
                address): 12, Krasnopresnenskaya Naberezhnaya, Moscow, 123610,
                and located at the following address (postal address): 88A,
                Sredny Prospekt, Vasilievsky Island, St. Petersburg, 199106
                (hereinafter referred to as the Operator) to transfer/provide my
                personal data to the following legal entity: SME Corporation
                (OGRN 1147711000040, INN 7750005919), registered at the
                following address (legal address): 4/1, Slavyanskaya Ploshchad,
                Moscow, 109074. This is with the aim of providing information
                about investment projects, and information concerning supply and
                demand. The data in question concerns the following:
            </Typography>
            <ul className={classes.list}>
                <Typography component={"li"} gutterBottom>
                    Full name
                </Typography>
                {!!isCitizenshipNeeded &&
                <Typography component={"li"} gutterBottom>
                    Citizenship
                 </Typography>
                }
                <Typography component={"li"} gutterBottom>
                    Telephone number
                </Typography>
                <Typography component={"li"} gutterBottom>
                    Email address
                </Typography>
                <Typography component={"li"} gutterBottom>
                    Information regarding place of work and job title
                </Typography>
            </ul>
            <Typography gutterBottom>
                <strong>
                    Consent is given to perform the following list of actions
                    with the personal data (plus general description of the
                    methods used by the Operator to process the personal data):
                </strong>
            </Typography>
            <Typography gutterBottom>
                The aforementioned personal data will be processed in an
                automated and non-automated (combined) manner. Actions performed
                with the personal data may include collecting, recording,
                collating, amassing, storing, adjusting (updating and/or
                changing), extracting, using, transferring (providing and/or
                granting access to), blocking, deleting, and erasing data.
            </Typography>
            <Typography gutterBottom>
                <strong>
                    Validity period for consent to processing personal data and
                    method of withdrawal (unless otherwise specified by federal
                    law):
                </strong>
            </Typography>
            <Typography gutterBottom>
                This consent is deemed valid from the day it is signed until the
                day it is withdrawn. Consent may be withdrawn in person, or in
                written form (including as an electronic document containing a
                simple electronic signature or qualified electronic signature).
                Notices should be emailed to info@roscongress.org or mailed to
                office 1101, 12/7, Krasnopresnenskaya Naberezhnaya, Moscow,
                123610, Russia.
            </Typography>
            <Typography gutterBottom>
                In this instance, the Foundation will cease processing the
                personal data in question, and erase it within 30 days of the
                day a withdrawal of consent notice is received, or within the
                timeframe specified in current legislation should there be no
                withdrawal of consent notice. I am aware that once the
                Foundation has received the withdrawal of consent notice, the
                right to provide information about investment projects, and
                information concerning supply and demand is simultaneously
                terminated.
            </Typography>
            <Typography gutterBottom>
                In providing consent, I hereby authorize SME Corporation to use
                the specified data in accordance with its rules and conditions.
            </Typography>
        </div>
    );
}

function En({ isCitizenshipNeeded }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Box display={"flex"} justifyContent={"center"}>
                <Typography variant={"h2"} gutterBottom>
                    PROVISION OF CONSENT TO PROCESSING PERSONAL DATA
                </Typography>
            </Box>
            <Typography gutterBottom>
                I hereby provide consent to the Roscongress Foundation
                (hereinafter referred to as the Foundation, OGRN 1077799005426,
                INN 7706412930), registered at the following address (legal
                address): 12, Krasnopresnenskaya Naberezhnaya, Moscow, 123610,
                and located at the following address (postal address): 88A,
                Sredny Prospekt, Vasilievsky Island, St. Petersburg, 199106
                (hereinafter referred to as the Operator) to transfer/provide my
                personal data to the following legal entity: SME Corporation
                (OGRN 1147711000040, INN 7750005919), registered at the
                following address (legal address): 4/1, Slavyanskaya Ploshchad,
                Moscow, 109074. This is with the aim of providing information
                about investment projects, and information concerning supply and
                demand. The data in question concerns the following:
            </Typography>
            <ul className={classes.list}>
                <Typography component={"li"} gutterBottom>
                    Full name
                </Typography>
                {!!isCitizenshipNeeded &&
                <Typography component={"li"} gutterBottom>
                    Citizenship
                 </Typography>
                }
                <Typography component={"li"} gutterBottom>
                    Telephone number
                </Typography>
                <Typography component={"li"} gutterBottom>
                    Email address
                </Typography>
                <Typography component={"li"} gutterBottom>
                    Information regarding place of work and job title
                </Typography>
            </ul>
            <Typography gutterBottom>
                <strong>
                    Consent is given to perform the following list of actions
                    with the personal data (plus general description of the
                    methods used by the Operator to process the personal data):
                </strong>
            </Typography>
            <Typography gutterBottom>
                The aforementioned personal data will be processed in an
                automated and non-automated (combined) manner. Actions performed
                with the personal data may include collecting, recording,
                collating, amassing, storing, adjusting (updating and/or
                changing), extracting, using, transferring (providing and/or
                granting access to), blocking, deleting, and erasing data.
            </Typography>
            <Typography gutterBottom>
                <strong>
                    Validity period for consent to processing personal data and
                    method of withdrawal (unless otherwise specified by federal
                    law):
                </strong>
            </Typography>
            <Typography gutterBottom>
                This consent is deemed valid from the day it is signed until the
                day it is withdrawn. Consent may be withdrawn in person, or in
                written form (including as an electronic document containing a
                simple electronic signature or qualified electronic signature).
                Notices should be emailed to info@roscongress.org or mailed to
                office 1101, 12/7, Krasnopresnenskaya Naberezhnaya, Moscow,
                123610, Russia.
            </Typography>
            <Typography gutterBottom>
                In this instance, the Foundation will cease processing the
                personal data in question, and erase it within 30 days of the
                day a withdrawal of consent notice is received, or within the
                timeframe specified in current legislation should there be no
                withdrawal of consent notice. I am aware that once the
                Foundation has received the withdrawal of consent notice, the
                right to provide information about investment projects, and
                information concerning supply and demand is simultaneously
                terminated.
            </Typography>
            <Typography gutterBottom>
                In providing consent, I hereby authorize SME Corporation to use
                the specified data in accordance with its rules and conditions.
            </Typography>
        </div>
    );
}
