const initialState = {
    cards: [],
    filterOptions: {
        branches: [],
        purposes: [],
        countries: [],
        presentations: []
    },
    filteredCards: [],
    cardsLoading: false,
    createdCard: false,
    isViewed: false,
    blocked: false
};

export function exchange(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'EXCHANGE_CARDS_LOADING':
            return Object.assign({}, state, {cardsLoading: action.loading});
        case 'EXCHANGE_CARDS_SET':
            return Object.assign({}, state, {cards: action.cards});
        case 'EXCHANGE_SET_FILTER_OPTIONS':
            return Object.assign({}, state, {filterOptions: action.filterOptions});
        case 'EXCHANGE_SET_FILTERED_CARDS':
            return Object.assign({}, state, {filteredCards: action.filteredCards});
        case 'EXCHANGE_SET_CREATED_CARD':
            return Object.assign({}, state, {createdCard: action.created});
        case 'EXCHANGE_SET_IS_VIEWED':
            return Object.assign({}, state, {isViewed: action.isViewed});
        case 'EXCHANGE_SET_BLOCKED':
            return Object.assign({}, state, {blocked: action.blocked});
        default:
            return state;
    }
}
