export function pollHasErrored(state = false, action) {
    switch (action.type) {
        case 'POLL_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function pollIsLoading(state = false, action) {
    switch (action.type) {
        case 'POLL_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function pollData(state = [], action) {
    switch (action.type) {
        case 'POLL_FETCH_DATA_SUCCESS':
            return action.poll;

        default:
            return state;
    }
}

export function pollResults(state = [], action) {
    switch (action.type) {
        case 'POLL_FETCH_RESULTS_SUCCESS':
            return action.poll;

        default:
            return state;
    }
}