export function eventHasErrored(state = false, action) {
    switch (action.type) {
        case 'EVENT_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function eventIsLoading(state = false, action) {
    switch (action.type) {
        case 'EVENT_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function event(state = [], action) {
    switch (action.type) {
        case 'EVENT_FETCH_DATA_SUCCESS':
            return action.event;

        default:
            return state;
    }
}