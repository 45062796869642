import React, { lazy } from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';
import configureStore from '../store/configureStore';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import '../elk.css';
import {mainMenu} from '../api/MainMenu';
import {t, language} from '@ksbteam/core/api/DummyTranslate';
import {getUrlParams} from "@ksbteam/core/api/UrlManager";
import Cookies from 'universal-cookie';
import ElkUser from "../api/ElkUser";
import RestClient from "@ksbteam/core/api/RestClient";
import Loadable from '../api/Loadable';
import { useRouteMatch, useLocation } from "react-router-dom";
import Footer from '../components/Footer';
import {fixupColorFonts} from '../FontManager';
import safeAreaInsets from 'safe-area-insets';

const RedirectPage = Loadable(lazy(() => import('./RedirectPage')));
const MaintenancePage = Loadable(lazy(() => import('./MaintenancePage')));


const theme = createTheme({
    typography: {
        h1: {
            fontSize: 32, //37 - в макете
            lineHeight: 1.15
        },
        h2: {
            fontSize: 25,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 16, //18 - в макете
        },
    },
    palette: {
        primary: {
            light: '#ab0d28',
            main: '#a50e2d',
        },
        secondary: {
            light: '#ab0d28',
            main: '#a50e2d',
        },
        text: {primary: '#5a5a5a'}
    }
});

const store = configureStore(); // You can also pass in an initialState here

const NoMatchPage = () => {
    const location = useLocation();
    let is_mobile = sessionStorage.getItem('is_mobile') === '1' || String(location.pathname).toLowerCase().match(/(ru|en)\/mobile\/(.*)/);
    const mainPath = is_mobile ? `/${language}/mobile/home` : '/'
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <h3>{t('Page not found')}</h3>
            <a href={mainPath}>{t('To main page')}</a>
        </div>
    );
};

const UseCustomLayout = (props) => {
    const location = useLocation();
    const match = useRouteMatch("/registration_participant_elk");
    const is_mobile = sessionStorage.getItem('is_mobile') === '1' || String(location.pathname).toLowerCase().match(/(ru|en)\/mobile\/(.*)/);
    const is_mobile_class = is_mobile ? ' is_mobile' : '';

    React.useEffect(() => {
        const el = document.querySelector('.app-top');
        if (el) {
            el.scrollIntoView({block: "start", behavior: "smooth"});
        }
    }, [location])

    return <div className={match !== null ? 'app-custom-wrapper app-top' : 'app-wrapper app-top minmaxheight' + is_mobile_class}>
        {props.children}
    </div>
}

export default class Elk extends React.Component {
    constructor(props) {
        super(props);
        let params = getUrlParams();
        const cookies = new Cookies();
        this.state = {
            redirecting: false
        };

        this.isMobile = sessionStorage.getItem('is_mobile') === '1' || false;
        if (!!params.token && !!params.token.length) {
            this.state = {
                redirecting: true
            };
            cookies.set('authToken', params.token, {path: '/'});

            this.elkUser =  new ElkUser();
            let user = this.elkUser;
            this.restClient = new RestClient({
                host: import.meta.env.VITE_REST_SERVICE,
                authToken: user.authToken,
                user,
                headers: {
                    application: user.isMobile ? 'mobile' : 'web'
                }
            });
            this.getUserType();
        }

        if((safeAreaInsets.support && safeAreaInsets.top !== 0) || params?.['safe-top']) {
            localStorage.setItem('safe-area-inset-top', String(Math.max(safeAreaInsets.top || 0, params?.['safe-top'] || 0)));
        }
        if((safeAreaInsets.support && safeAreaInsets.bottom !== 0) || params?.['safe-bottom']) {
            localStorage.setItem('safe-area-inset-bottom', String(Math.max(safeAreaInsets.bottom || 0, params?.['safe-bottom'] || 0)));
        }

        cookies.set('testElk', 1, {path: '/'});
        if ((!!params.mobile && params.mobile === '1') || String(window.location.pathname).toLowerCase().match(/(ru|en)\/mobile\/(.*)/)){
            sessionStorage.setItem('is_mobile', '1');
            cookies.set('twoStepAuth', 1, {path: '/'});
            this.isMobile = true;
        }

        if (!!params.redirect_uri && !!params.redirect_uri.length){
            let rdUri;
            try {
                rdUri = decodeURI(decodeURIComponent(params.redirect_uri));
            } catch (e) {
                rdUri = document.referrer;
            }

            sessionStorage.setItem('afterlogin_redirect', rdUri);
        }

        if (!this.elkUser?.loggedIn() && !window.location.pathname.match(/login|registration/g)) {
            sessionStorage.setItem('afterlogin_go_to', window.location.href);
        }
        this.checkRedirect();
        this.checkUTMTags();
    }

    componentDidMount () {
        fixupColorFonts();
    }

    checkRedirect = async () => {
        if (!this.elkUser){
            this.elkUser =  new ElkUser();
        }
        let externalRedirect = sessionStorage.getItem('afterlogin_redirect');
        if (!!externalRedirect && this.elkUser.twoStepAuth === '1'){
            let user = this.elkUser;
            this.restClient = new RestClient({
                host: import.meta.env.VITE_REST_SERVICE,
                authToken: user.authToken,
                user,
                headers: {
                    application: user.isMobile ? 'mobile' : 'web'
                }
            });

            let check = await this.restClient.get('elk/login/info');
            // console.log({check});
            if (!check || check?.result !== 'success'){
                await user.logout();
                // console.log('do logout');
            } else {
                sessionStorage.removeItem('afterlogin_redirect');
                try {
                    let redirectUrl = new URL(externalRedirect.match(/^http(s)?/)
                        ? externalRedirect
                        : window.location.protocol + '//' + externalRedirect);
                    redirectUrl.searchParams.append('token', this.elkUser.authToken);
                    // console.log('do redirect');
                    this.setState({redirecting: true});
                    window.location = redirectUrl.href;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    checkUTMTags = async () => {
        const utmTags = ['utm_source', 'utm_medium', 'utm_campaign'];
        const utmValues = new Map();
        const searchParams = new URLSearchParams(window.location.search);

        for (const tagName of utmTags) {
            const paramValue = searchParams.get(tagName);

            if (!paramValue) continue;

            utmValues.set(tagName, paramValue);
        }

        if (utmValues.size > 0) {
            const utmObj = Object.fromEntries(utmValues)
            window.sessionStorage.setItem('utmTags', JSON.stringify(utmObj));
        }
    }

    getUserType = async () => {
        if (this.restClient && this.elkUser){
            let info = await this.restClient.get('icrobjects/login/user-info');
            this.elkUser.accessType = info.access_type || '';
            if (info?.two_factor_enabled === false){
                this.elkUser.twoStepAuth = 1;
            }
            this.setState({redirecting: false});
        }

    }

    render() {

        if(parseInt(localStorage.getItem('safe-area-inset-top')) > 0) {
            document.documentElement.style.setProperty('--sat', localStorage.getItem('safe-area-inset-top') + "px");
        }
        if(parseInt(localStorage.getItem('safe-area-inset-bottom')) > 0) {
            document.documentElement.style.setProperty('--sab', localStorage.getItem('safe-area-inset-bottom') + "px");
        }

        if (this.state.redirecting){
            return <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <RedirectPage />
                </MuiThemeProvider>
            </Provider>
        }
        let routerItems = mainMenu.getRouterItems();
        return (
            <Provider store={store}>
                <Router forceRefresh={this.isMobile || undefined}>
                    <MuiThemeProvider theme={theme}>
                        <UseCustomLayout>
                            <CssBaseline/>
                            <Switch>
                                {routerItems.map(item =>
                                    <Route key={item.url} exact path={item.url} component={item.component}/>
                                )}
                                <Route exact path={'/maintenance'} component={MaintenancePage}/>
                                <Route component={NoMatchPage}/>
                            </Switch>
                        </UseCustomLayout>
                        <Route path={'/registration_participant_elk'}
                           children={({match}) => {
                                   let is_mobile = this.isMobile;
                                   if (match === null && !is_mobile) {
                                       return <Footer/>;
                                   }
                                   return null;
                               }
                          }
                        />
                    </MuiThemeProvider>
                </Router>
            </Provider>
        );
    }
}
