export function materialsHasErrored(state = false, action) {
    switch (action.type) {
        case 'MATERIALS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function materialsIsLoading(state = false, action) {
    switch (action.type) {
        case 'MATERIALS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function materials(state = [], action) {
    switch (action.type) {
        case 'MATERIALS_FETCH_DATA_SUCCESS':
            return action.materials;

        default:
            return state;
    }
}

export function userData(state = [], action) {
    switch (action.type) {
        case 'MATERIALS_FETCH_USER_DATA_SUCCESS':
            return action.userData;

        default:
            return state;
    }
}

export function materialsSubscribeError(state = false, action) {
    switch (action.type) {
        case 'SUBSCRIBE_ERROR':
            return action.subsError;

        default:
            return state;
    }
}

export function materialsSubscribeProcessing(state = false, action) {
    switch (action.type) {
        case 'SUBSCRIBE_PROCESSING':
            return action.subsProcessing;

        default:
            return state;
    }
}
