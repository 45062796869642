import {
    SET_USE_MESSAGING,
    SET_REST_CLIENT,
    SET_MEMBERS,
    SET_MEMBERS_LOADING,
    SET_LOADED,
    SET_EAMIL_NOTIFICATIONS,
    SET_FILTERS,
    SET_ISMOBILE,
    SET_USER_CHAT_OBJECT
} from '../constants/Messaging';

const initialState = {
    useMessaging: false,
    emailNotifications: false,
    loaded: false,
    restClient: null,
    members: [],
    membersLoading: false,
    filters: {},
    isMobile: true,
    userChatObject: {}
}

const Messaging = (state = initialState, action) => {
    switch (action.type) {
        case SET_ISMOBILE:
            return {...state, isMobile: action.isMobile}
        case SET_FILTERS:
            return {...state, filters: {...action.filters}}
        case SET_LOADED:
            return {...state, loaded: action.loaded}
        case SET_MEMBERS:
            return {...state, members: action.members}
        case SET_MEMBERS_LOADING:
            return {...state, membersLoading: action.membersLoading}
        case SET_USE_MESSAGING:
            return {...state, useMessaging: action.useMessaging}
        case SET_EAMIL_NOTIFICATIONS:
            return {...state, emailNotifications: action.emailNotifications}
        case SET_REST_CLIENT:
            return {...state, restClient: action.restClient}
        case SET_USER_CHAT_OBJECT:
            return {...state, userChatObject: action.userChatObject}
        default:
            return state;
    }
}

export default Messaging;