import React from "react";
import { Box, Button, Dialog, DialogContent } from "@material-ui/core";
import ConsentDataTransfer from "../ConsentDataTransfer";
import { language } from "@ksbteam/core/api/DummyTranslate";

function DataTransferDialog({isDialogOpen, handleDialogClose, handleAgreeClick}) {
    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogContent>
                <ConsentDataTransfer isCitizenshipNeeded={true} />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                        p: "0",
                        m: "0",
                        height: "auto",
                        mt: "16px",
                    }}
                ></Box>
                <Box
                    sx={{
                        width: "100%",
                        margin: "16px auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ width: "20%" }}
                        onClick={handleAgreeClick}
                    >
                        {language === "ru" ? "Согласиться" : "Accept"}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ width: "20%" }}
                        onClick={handleDialogClose}
                    >
                        {language === "ru" ? "Отказаться" : "Decline"}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default DataTransferDialog;
