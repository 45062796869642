const initialState = {
    messaging: null,
    subscribe: null,
    granted: false,
    support: false,
    token: '',
    subscribed: false,
    supplyNotifications: false
}

export const fireBase = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MESSAGING':
            return {...state, messaging: action.messaging}
        case 'SET_SUBSCRIBE':
            return {...state, subscribe: action.subscribe}
        case 'SET_GRANTED':
            return {...state, granted: action.granted}
        case 'SET_SUPPORT':
            return {...state, support: action.support}
        case 'SET_TOKEN':
            return {...state, token: action.token}
        case 'SET_SUBSCRIBED':
            return {...state, subscribed: action.subscribed}
        case 'SET_NOTIFICATION_SUPPLIED':
            return {...state, supplyNotifications: action.supplyNotifications}
        default:
            return state;
    }
}
