export const SET_USE_MESSAGING = 'SET_USE_MESSAGING';
export const SET_EAMIL_NOTIFICATIONS = 'SET_EAMIL_NOTIFICATIONS';
export const SET_REST_CLIENT = 'SET_REST_CLIENT';
export const SET_LOADED = 'SET_LOADED';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_MEMBERS_LOADING = 'SET_MEMBERS_LOADING';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_ISMOBILE = 'SET_ISMOBILE';
export const SET_USER_CHAT_OBJECT = 'SET_USER_CHAT_OBJECT';


export const ARR_EN = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const ARR_RU = ['а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ы', 'э', 'ю', 'я'];