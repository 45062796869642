import {
    SET_CHATS,
    SET_LOADING,
    SET_CHAT_LOADING,
    SET_SOCKET,
    SET_TOKEN,
    SET_PING_TIMER,
    SET_UNREAD_COUNT
} from '../constants/Chat';

const initialState = {
    socket: null,
    chatToken: null,
    pingTimer: null,
    chats: {},
    loading: true,
    chatLoading: false,
    unreadMessages: 0
}

const Chat = (state = initialState, action) => {
    switch (action.type) {
        case SET_UNREAD_COUNT:
            return {...state, unreadMessages: action.unreadMessages}
        case SET_PING_TIMER:
            return {...state, pingTimer: action.pingTimer}
        case SET_TOKEN:
            return {...state, chatToken: action.chatToken}
        case SET_SOCKET:
            return {...state, socket: action.socket}
        case SET_CHATS:
            return {...state, chats: {...action.chats}}
        case SET_LOADING:
            return {...state, loading: action.loading}
        case SET_CHAT_LOADING:
            return {...state, chatLoading: action.chatLoading}
        default:
            return state;
    }
}

export default Chat;